import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.scss']
})
export class PageBannerComponent implements OnInit {

  @Input('image') image: string;
  @Input('header') header: string;
  @Input('content') content: string;

  constructor() { }

  ngOnInit(): void {
  }


}
