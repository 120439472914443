import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';

import { DefaultComponent } from './default.component';
import { HomeComponent } from 'src/app/modules/home/home.component';
import { AboutComponent } from 'src/app/modules/about/about.component';
import { ServicesComponent } from 'src/app/modules/services/services.component';
import { EnvironmentComponent } from 'src/app/modules/environment/environment.component';
import { CareersComponent } from 'src/app/modules/careers/careers.component';
import { ContactComponent } from 'src/app/modules/contact/contact.component';
import { LocationsComponent } from 'src/app/modules/locations/locations.component';
import { PrivacyComponent } from 'src/app/modules/privacy/privacy.component';
import { ThankYouComponent } from 'src/app/modules/thank-you/thank-you.component';

@NgModule({
  declarations: [
    DefaultComponent,
    HomeComponent,
    AboutComponent,
    ServicesComponent,
    EnvironmentComponent,
    CareersComponent,
    LocationsComponent,
    ContactComponent,
    PrivacyComponent,
    ThankYouComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    NgbModule
  ]
})
export class DefaultModule { }
