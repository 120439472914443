<address>
    <strong>Jem Logistics Limited</strong> <br />
    C/O G2S Coupland Road <br />
    Hindley Green  <br />
    Wigan <br />
     <a target="_blank"
         href="https://goo.gl/maps/NBYyYnu3JcfUCxw68">
         <strong>WN2 4HT</strong>  
     </a><br />
    
 </address>