import { Component, OnInit } from '@angular/core';
import { Associate } from 'src/app/interfaces/associate';
import { OwlOptions } from 'ngx-owl-carousel-o';
   
import associates from 'src/_files/associates.json';

@Component({
  selector: 'app-associates',
  templateUrl: './associates.component.html',
  styleUrls: ['./associates.component.scss']
})
export class AssociatesComponent implements OnInit {
  public items: Associate[] = associates;
  constructor() { }

  ngOnInit(): void {
  }

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      440: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }
}
