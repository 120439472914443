import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public pageHeader: PageHeader = {
    image: '/assets/about-banner.jpeg',
    header: "About <br/> Jem Logistics",
    content: getConent(),
  }   
}
function getConent(): string {
 return ''
}
