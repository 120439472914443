
    <app-page-banner [image]=pageHeader.image [header]=pageHeader.header [content]=pageHeader.content></app-page-banner>
    <app-careers-banner></app-careers-banner>

    <section class="mb-5">
        <div class="row m-0">
            <div class="col-md-6 p-0 m-0">
                <div class="content--inner-text">
                    <h3>Vehicle Operations</h3>
                    <p>
                        With a fleet mainly using Mercedes-Benz Actros tractor units we provie a perfect combination of enconomy and ecology. Using sophisticated engine technology,
                        our units are able to provide the ultimate enconomy whilst still delivering maximum robustness. We also offer a tractor unit only service when you supply your own trailor.
                    </p>
                </div>
            </div>
            <div class="col-md-6 m-0 content--img-right">
            </div>
        </div>
    </section>

    <section class="mt-5 mb-5">
        <div class="row m-0">
            <div class="col-md-6 m-0 content--img-left hide-mob" style="background-image: url('assets/curtain-side.jpeg');">
            </div>
            <div class="col-md-6 p-0 m-0">
                <div class="content--inner-text">
                    <h3>Fleet</h3>
                    <p>
                        Operating some 15 vehicles and 25 box vans with tail lifts. Our fleet comprises of refridgerated tail lift units as well as curtain side tail lift units. All of our
                        vehicles are fitted with tracking devices which helps monitor delivery times.
                    </p>
                </div>
            </div>
            <div class="col-md-6 m-0 content--img-left mob-only" style="background-image: url('assets/curtain-side.jpeg');">
            </div>
        </div>
    </section>

