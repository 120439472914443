<app-page-banner [image]=pageHeader.image [header]=pageHeader.header [content]=pageHeader.content></app-page-banner>
    <section class="mb-5">
        <div class="row m-0">
            <div class="col-md-6 m-0 content--img-left" style="background-image: url('/assets/careers-side.jpeg')">
            </div>
            <div class="col-md-6 p-0 m-0">
                <div class="content--inner-text text-center">
                    <h3>How to Apply</h3>
                    <p>To apply please submit your CV to:<br/>
                        <a href="mailto:careers@jem-logistics.co.uk">careers@jem-logistics.co.uk</a>
                    </p>
                    <p>
                        or use the contact form below.
                    </p>
                    <p>
                        Jem Logistics Limited is an equal opportunities employer.
                    </p>
                </div>
            </div>         
        </div>
    </section>
    <div class="content--header-bg">
        <h2 class="m-0"> Apply Here</h2>
    </div>
    <section>
        <app-careers-form></app-careers-form>
    </section>

