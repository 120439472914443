<footer class="site-footer">
    <div class="container">
      <div class="row">
        <div class="col-sm-12 col-md-6">
          <h6>About</h6>
          <div class="text-justify">
            <app-about-blurb></app-about-blurb>
          </div>
          <app-address></app-address>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Locations</h6>
          <ul class="footer-links" *ngFor="let l of locations">
            <li><a href="/locations#{{l.url}}">{{l.name}}</a></li>
          </ul>
        </div>

        <div class="col-xs-6 col-md-3">
          <h6>Quick Links</h6>
          <ul class="footer-links">
            <li><a href="/services">Services</a></li>
            <li><a href="/careers">Careers</a></li>
            <li><a href="/fleet">Fleet</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/privacy-policy/">Privacy Policy</a></li>
            <li>&nbsp;</li>
          </ul>
        </div>
      </div>
      <hr>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8 col-sm-6 col-xs-12">
          <p class="copyright-text">Copyright &copy; {{anio}} All Rights Reserved by 
       <a href="#">Jem Logistics Ltd</a>. <br/>
       <a href="https://www.discovr-it.com" target="_blank">Created and maintained by Discovr IT</a>
          </p>
        </div>

        <div class="col-md-4 col-sm-6 col-xs-12">
          <ul class="social-icons">       
            <li><a class="facebook" target="_blank" href="#"><i class="fab fa-facebook-f"></i></a></li>
            <li><a class="twitter" target="_blank" href="#"><i class="fab fa-twitter"></i></a></li>  
            <li><a class="dribbble" href="tel:+441204795347"><i class="fa fa-phone"></i></a></li> 
          </ul>
        </div>
      </div>
    </div>
</footer>
