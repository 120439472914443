<section>
    <video [muted]="true" autoplay muted defaultMuted playsinline loop id="video">
        <source src="assets/video.mp4" type="video/mp4">

    </video>
    <div class="style-bar"> </div>
    <div class="page--header">
        <h2 style="text-align: left;">About <br/> Jem Logistics Ltd</h2>
    </div>

<div class="container pl-5 pr-5">
    <app-about-blurb></app-about-blurb>
</div>
<section>

<app-careers-banner></app-careers-banner>

<section>
    <div class="row m-0">
        <div class="col-md-6 p-0 m-0">
            <div class="content--inner-text">
                <p>
                Operating 24 hours a day 7 days a week.<br />
                We try to offer truly visible solutions, suppling not only a perfect, tailored service, but information that is important to you, when you need it. <br /><br />
                We are here to support and we aim to add value.
                </p>
            </div>
        </div>
        <div class="col-md-6 m-0 content--img-right">
        </div>
    </div>
</section>

<div class="content--header-bg">
    <h2 class="m-0"> Who we work with</h2>
</div>
<section class="mt-5 mb-5 p-3">
    <app-associates></app-associates>
</section>
