import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';
import { Service } from 'src/app/interfaces/service';

import services from 'src/_files/services.json';
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public items: Service[] = services;
  constructor() { }

  ngOnInit(): void {
  }
  public pageHeader: PageHeader = {
    image: '/assets/services-banner.jpeg',
    header: "We  <br/>Deliver.",
    content: getConent(),
  }   
}
function getConent(): string {
 return ''
}
