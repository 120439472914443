import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public pageHeader: PageHeader = {
    image: '/assets/careers.jpeg',
    header: "Careers <br/> Come and join the team.",
    content: getConent(),
  }   
}
function getConent(): string {
 return '<h2>C+E Drivers (HGV 1)</h2> <p> Jem Logistics are seeking experienced C+E drivers to operate from our national hubs. <br/><br/> All applicants must possess digital tacho card and be fluent in both written and spoken English. Have good geographical knowledge of the United Kingdom and have excellent time management skills. </p>'
}
