import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';

@Component({
  selector: 'app-environment',
  templateUrl: './environment.component.html',
  styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  public pageHeader: PageHeader = {
     image: '/assets/fleet-banner.jpeg',
     header: "Our Fleet <br/> It's how we deliver.",
     content: getConent(),
   }   
}
function getConent(): string {
  return '<h4>Envionrment Statement</h4><p>Over the years it has become an increasing concern over the impact transportation has on our environment. Jem Logistics NW aims to cut down our impact and reduce our carbon offset whilst still offering a first class service.</p>'
}

