import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { AddressComponent } from './components/address/address.component';
import { CareersBannerComponent } from './components/careers-banner/careers-banner.component';
import { AssociatesComponent } from './components/associates/associates.component';
import { AboutBlurbComponent } from './components/about-blurb/about-blurb.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';

import {MatIconModule} from '@angular/material/icon';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatMenuModule} from '@angular/material/menu';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CareersFormComponent } from './components/careers-form/careers-form.component';
import { PageBannerComponent } from './components/page-banner/page-banner.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    AddressComponent,
    CareersBannerComponent,
    AssociatesComponent,
    AboutBlurbComponent,
    ContactFormComponent,
    CareersFormComponent,
    PageBannerComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    MatIconModule,
    MatToolbarModule,
    MatMenuModule,
    CarouselModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    AddressComponent,
    CareersBannerComponent,
    AssociatesComponent,
    AboutBlurbComponent,
    ContactFormComponent,
    CareersFormComponent,
    PageBannerComponent
  ]
})
export class SharedModule { }
