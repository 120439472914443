import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  public pageHeader: PageHeader = {
    image: '/assets/contact-banner.jpeg',
    header: "Contact Us <br/> Speak to a member of the team.",
    content: getConent(),
  }   
}
function getConent(): string {
 return ''
}
