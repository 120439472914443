<section class="form-container">
    <form action="https://mailthis.to/discovr-it" method="POST" encType="multipart/form-data">

        <!-- Name -->
        <div class="row m-0">
            <div class="form-group m-1">
                <input class="form-control" type="text" name="name" placeholder="Full Name">
            </div>
        </div>

        <!-- Email -->
        <div class="form-group m-1">
            <div class="row m-0">
                <div class="col-md-6 mt-1 mb-1">
                    <input class="form-control" type="email" name="email" placeholder="Email Address">
                </div>
                <div class="col-md-6 mt-1 mb-1">
                    <input class="form-control" type="text" name="phone" placeholder="Telephone">
                </div>
            </div>
        </div>



        <!-- Textarea (Message) -->
        <div class="row m-0">
            <div class="form-group m-1">
                <textarea class="form-control" name="message" placeholder="Add a cover letter here"
                    style="height:90px"></textarea>
            </div>
        </div>
        <!-- File attachments -->
        <div class="row m-0">
            <div class="form-group m-1 mt-3">
                <label class="mb-3 highlight" for="file">Attach your CV</label>
                <input class="form-control" type="file" name="file" placeholder="">
            </div>
        </div>

        <!-- Optional (hidden) fields -->
        <!-- Custom email Subject -->
        <input type="hidden" name="_subject" value="Job Application Received">
        <!-- Custom email ReplyTo Address -->
        <input type="hidden" name="_replyto" value="transport@JemLogistics.uk">
        <!-- Redirect to a page after recaptcha -->
        <input type="hidden" name="_after" value="http://JemLogistics.uk/thank-you">
        <!-- Honeypot (to catch comment spam bots) -->
        <input type="hidden" name="_honeypot" value="">
        <!-- Apply a custom confirmation message on the second step -->
        <input type="hidden" name="_confirmation" value="Applied!">

        <!-- Submit Button -->
        <div class="row mt-0">
            <div class="form-group mt-3 ml-1">
                <div class="btn-background">
                    <input type="submit" name="submit" value="Apply Now" class="btn btn-lg btn-primary">
                </div>
            </div>
        </div>
    </form>
</section>