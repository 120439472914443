<app-page-banner [image]=pageHeader.image [header]=pageHeader.header [content]=pageHeader.content></app-page-banner>
<section class="p-2">
    <ng-container *ngFor="let i of items">
        <div class="service-bg">
        <div class="row m-0">
            <div id="{{i.url}}" class="col-md-6 m-0 p-5">
                <h2 class="text-white">{{i.name}}</h2>
            </div>
            <div class="col-md-6 m-0 p-5">
                <p class="m-2">
                    <br /><br />
                    <br /><br />
                   <span [innerHtml]=i.description></span> <br />
                </p>
            </div>
        </div>
    </div>
    </ng-container>
</section>
