import { Component, HostBinding, HostListener, OnInit } from '@angular/core';
import { Service } from 'src/app/interfaces/service';

import locationsFile from 'src/_files/locations.json';
import servicesFile from 'src/_files/services.json';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public isFixedNavbar :boolean;
  public isNavbarCollapsed: boolean = true;


  public locations: Location[] = locationsFile;
  public services: Service[] = servicesFile

  @HostBinding('class.navbar-opened') navbarOpen = false;

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const offset = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    if(offset > 10) {
      this.isFixedNavbar = true;
    } else {
      this.isFixedNavbar = false;
    }
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

}
