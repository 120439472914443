
    <nav [ngClass]="{'bg-custom': isNavbarCollapsed != false}" class="navbar navbar-expand-md navbar-custom sticky-top">
        <a class="navbar-brand" href="#">
            <img src="assets/logo.png" alt="Jem Logistics NW Ltds" height="65" class="d-inline-block align-top">
            </a>
        <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span class="line"></span> 
            <span class="line"></span> 
            <span class="line" style="margin-bottom: 0;"></span>
          </button>
        <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
            <ul class="navbar-nav ml-auto"> </ul>
            <ul class="navbar-nav">
                <li class="nav-item on-mob">
                    <a class="nav-link" href="/about">About</a>
                </li>
                <li class="nav-item dropdown" ngbDropdown>     
                    <span class="nav-link dropdown-toggle mouse-over" id="id01" ngbDropdownToggle>Services</span>
                    <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
                        <div *ngFor="let l of services">
                            <a class="dropdown-item" href="/services#{{l.url}}">{{l.name}}</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item dropdown" ngbDropdown>     
                    <span class="nav-link dropdown-toggle mouse-over" id="id02" ngbDropdownToggle>Locations</span>
                    <div class="dropdown-menu" aria-labelledby="id02" ngbDropdownMenu>
                        <div *ngFor="let l of locations">
                            <a class="dropdown-item" href="/locations#{{l.url}}">{{l.name}}</a>
                        </div>
                    </div>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/careers">Careers</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/fleet">Fleet</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="/contact">Contact Us</a>
                </li>
            </ul>
        </div>
        <div class="nav--style-bar"> </div>
    </nav>