<app-page-banner [image]=pageHeader.image [header]=pageHeader.header [content]=pageHeader.content></app-page-banner>

<div class="container pt-5 pb-5">

    <div class="row">
        <div class="col-md-4 p-4">
            <h4 class="mb-3 highlight">Head Office</h4>
            <app-address></app-address>
            <div class="row mt-5 mb-5">
                <div class="col-12">
                    <h6 class="highlight"></h6>
                    <!-- <span>Office: </span><a href="tel:+">0161 123 4567</a> <br/> -->
                    <span>Email: </span><a href="mailto:transport@jem-logistics.co.uk">transport@jem-logistics.co.uk</a>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1185.8201538192768!2d-2.5450320416439376!3d53.52848049500443!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b07dc40e2719f%3A0xa8539406c6239759!2sG2s%20Ltd%20warehouse%203!5e0!3m2!1sen!2suk!4v1627561541734!5m2!1sen!2suk" width="600" height="400" style="border:0; max-width: 95vw; border-radius: 5px;" allowfullscreen="" loading="lazy"></iframe>
        </div>
    </div>
    </div>

    <div class="content--header-bg">
        <h2 class="m-0"> Contact Us</h2>
    </div>

    <app-contact-form></app-contact-form>

    <h4>Who we work with</h4>
    <section class="mt-5 mb-5 p-3">

        <app-associates></app-associates>
    </section>