import { Component, OnInit } from '@angular/core';

import locationsFile from 'src/_files/locations.json';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public locations: Location[] = locationsFile;
  anio: number = new Date().getFullYear();
  constructor() { }

  ngOnInit(): void {
  }

}
