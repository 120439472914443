import { Component, OnInit } from '@angular/core';
import { PageHeader } from 'src/app/interfaces/pageHeader';

import locations from 'src/_files/locations.json';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent implements OnInit {
  public items: Location[] = locations;
  constructor() { }

  ngOnInit(): void {
  }
  public pageHeader: PageHeader = {
    image: '/assets/locations-banner.jpeg',
    header: "Our Hubs  <br/>Situated all over the UK",
    content: getConent(),
  }   
}
function getConent(): string {
 return ''
}
