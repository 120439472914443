import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DefaultComponent } from './layouts/default/default.component';
import { AboutComponent } from './modules/about/about.component';
import { CareersComponent } from './modules/careers/careers.component';
import { ContactComponent } from './modules/contact/contact.component';
import { EnvironmentComponent } from './modules/environment/environment.component';
import { HomeComponent } from './modules/home/home.component';
import { LocationsComponent } from './modules/locations/locations.component';
import { PrivacyComponent } from './modules/privacy/privacy.component';
import { ServicesComponent } from './modules/services/services.component';
import { ThankYouComponent } from './modules/thank-you/thank-you.component';

const routes: Routes = [
  { path: '', 
    component: DefaultComponent, 
    children: [ {
      path: '',
      component: HomeComponent
    },
    {
      path: 'about',
      component: AboutComponent
    },
    {
      path: 'locations',
      component: LocationsComponent
    },
    {
      path: 'services',
      component: ServicesComponent
    },
    {
      path: 'fleet',
      component: EnvironmentComponent
    },
    {
      path: 'careers',
      component: CareersComponent
    },
    {
      path: 'contact',
      component: ContactComponent
    },
    {
      path: 'privacy-policy',
      component: PrivacyComponent
    },
    {
      path: 'thank-you',
      component: ThankYouComponent
    }
  ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
